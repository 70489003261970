import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import UserGuide from '../pages/UserGuide.vue'
import Signup from '../pages/Signup.vue'
import Services from '../pages/Services.vue'
import Products from '../pages/Products.vue'
import Privacy from '../pages/Privacy.vue'
import Support from '../pages/Support.vue'
import BetaProgram from '../pages/BetaProgram.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  // {
  //   path: '/beta',
  //   name: 'beta',
  //   component: BetaProgram,
  // },
  // {
  //   path: '/user-guide',
  //   name: 'user-guide',
  //   component: UserGuide,
  // },
  // {
  //   path: '/signup',
  //   name: 'Signup',
  //   component: Signup
  // },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },

})

export default router
