<template>
<div class="content-boundaries px-6 py-10">    
    <div class="text-xl font-medium underline">Support</div>
    <div class="mt-4">Need help? Check our <router-link to="/user-guide">User Guide</router-link>, or email us at <a href = "mailto: info@skoookum.com" class="underline">info@skoookum.com</a>.</div>
</div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
@import '../styles/main.scss';

</style>
