<template>
    <div class="sm:rounded-t flex flex-col pt-12 pb-16 px-8 bg-stone-900 text-stone-200">
      <router-link id="logo-text-footer" class="mb-4" to="/">
        <div class="font-semibold  text-xl tracking-normal">skoookum</div>
        <div class="text-xs  tracking-wide leading-none">{{ new Date().getFullYear() }} &copy; </div>
      </router-link>
      
      <div class="flex flex-row text-2xl mb-8 border-b border-stone-200 pb-2">
        <a href="https://twitter.com/sk3kum" target="_blank">
          <div class="mr-4">
              <i class="fa-brands fa-x-twitter"></i>
          </div>
        </a>
        <a href="https://www.youtube.com/channel/UCv_QEvpTjnIq_PuFXKG6IsQ" target="_blank">
          <div class="mr-4">
            <i class="fa-brands fa-youtube"></i>
          </div>
        </a>
        <a href="https://discord.gg/VEXACKcP" target="_blank">
          <div class="mr-4">
            <i class="fa-brands fa-discord"></i>
          </div>
        </a>
      </div>
    
      <div class="flex flex-row justify-between">
        <div class="flex flex-col flex-1">
          <router-link class="py-2" to="/products">Products</router-link>
          <router-link class="py-2" to="/services">Services</router-link>
        </div>
        <div class="flex flex-col flex-1">
          <div class="py-2"><router-link to="/privacy" activeClass="underline">Privacy</router-link></div>
          <div class="py-2"><router-link to="/support" activeClass="underline">Support</router-link></div>
        </div>
      </div>
    </div>
</template>
