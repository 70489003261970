<template>
    <div class="w-full flex flex-col items-center mx-6 sm:p-4">  
        <div class="w-full lg:w-auto">
            <div class="flex flex-col justify-center items-start sm:items-center pt-16 lg:pt-32 pb-16 md:pb-20 lg:pb-32 lg:flex-row lg:justify-center lg:items-center content-boundaries">
                <div class="flex flex-col sm:text-center sm:justify-center lg:mr-10 lg:items-end lg:text-right lg:justify-end">
                    <h1 class="text-4xl md:text-5xl lg:text-6xl xl:text-7xl italic font-black  text-stone-900 whitespace-nowrap">Power Platform</h1>
                    <h1 class="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-black italic text-stone-900  leading-none ">Solutions</h1>
                </div>
                <div class="flex flex-col w-full justify-start">
                    <Inquiry class="w-full mt-14 lg:mt-0" :inquiryPlaceholder="'How can we help?'"/> 
                </div>
            </div>
        </div>
        <div class="">
            <div class="long-text-container bg-opacity-100  bg-indigo-700  text-stone-200 flex flex-col justify-end rounded-t">
                <p class="long-text"><span class="font-bold tracking-wide">skoookum</span> provides a white-glove service to help you augment existing workflows and consolidate third-party applications into your Office 365 subscription.</p>
                <p class="long-text mt-4" >In a more technical sense, we liberate your data and centralize your business workflows on top of the Dataverse.</p>
            </div>
            <div class="long-text-container bg-opacity-85 bg-indigo-700 text-stone-200 font-medium rounded-b">
                <div class="font-bold italic text-lg md:text-xl lg:text-2xl mb-6 mx-1">Wait, what does that mean?</div>            
                <div class="flex flex-col">
                    <p class="long-text">It's different for everyone, but for most it means that we convert your business-critical Excel registers and Sharepoint tables into Dataverse Entities, and your disparate Outlook threads into <a href="https://learn.microsoft.com/en-us/power-automate/business-process-flows-overview" target="_blank" class="underline">Business Process Flows</a>.</p>
                    <p class="long-text mt-4" >Sometimes it means that we can replace entire third-party applications by stitching together a couple Power Platform components.</p>
                </div>
                <div class="font-bold italic text-lg md:text-xl lg:text-2xl mt-10 mb-6 mx-1">Ok, how do you do it?</div>
                <div class="flex flex-col">
                    <p class="long-text">We're a small team of focused developers and we're agile. For real. You'll have working software quickly and we'll iterate into meaningful solutions.</p>
                    <p class="long-text mt-4" >Your key stakeholders will have access to the product throughout its development, providing a truly collaborative experience.</p>
                    <p class="long-text mt-4" >Our ultimate goal is to ship on time and under budget.</p>
                </div>
                <div class="font-bold italic text-lg md:text-xl lg:text-2xl mt-10 mb-6 mx-1">I love it, how long does it take?</div>
                <div class="flex flex-col">
                    <p class="long-text">How long we spend on you is totally variable. But there are some knowns: We iterate quickly, and we mean it when we say we're agile. Particularly when it comes to <a href="https://agilemanifesto.org/" target="_blank" class="underline ">customer collaboration</a>.</p>
                    <p class="long-text mt-4" >We try to keep our initial contracts short, usually 3-6 weeks, and we typically deliver some slice of value at the halfway mark. The reality is that the platform can't do everything, and your needs are complex. So we get our hands dirty early, and deliver a solution that fully solves one of your problems.</p>
                    <p class="long-text mt-4" >You get to see a cross-section of the system, provide critical feedback throughout the realization process, and know exactly what you're getting way before your deadlines.</p>
                </div>
            </div>
        </div>
        <div class="w-full flex flex-col mx-auto items-center justify-center content-boundaries">
            <Inquiry class="my-16 w-full lg:w-auto" :inquiryPlaceholder="'I\'d like to get in touch with your Implementation Team.'"/>
        </div>
    </div>
</template>

<script>
import Inquiry from '../components/Inquiry.vue';
import FindUs from '../components/FindUs.vue';
export default {
    components: {
        Inquiry,
        FindUs,
    }
}
</script>
