<template>
  <div class="flex flex-col content-boundaries min-h-dvh">
    <div class="flex flex-col lg:flex-row lg:justify-center lg:items-center lg:pb-32 mx-6 h-full pt-32 pb-10 justify-center text-stone-900 ">
      <div class="flex flex-col lg:items-end lg:justify-center pr-10  lg:text-right">
        <span class="leading-none text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-black italic">Get The Most</span>
        <span class="leading-none text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-black italic">Out of <span style="white-space: nowrap;">Microsoft</span></span>
        <FindUs />
      </div>
      <div class="flex lg:justify-start">
        <Inquiry class="mt-14 lg:mt-0 w-full" :inquiryPlaceholder="'How do I build my business on top of the Dataverse?'"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '../components/products/ProductCard';
import Inquiry from '../components/Inquiry';
import FindUs from '../components/FindUs';
export default {
  name: 'HomeView',
  components: {
    ProductCard,
    Inquiry,
    FindUs,
  },
  data () {
    return {
      products: {
        excel: {
          name: 'Excel',
          class: 'clickable-green',
          color: 'green',
        },
        outlook: {
          name: 'Outlook',
          class: 'clickable-blue',
          color: 'blue',
        },
        powerpoint: {
          name: 'PowerPoint',
          class: 'clickable-orange',
          color: 'orange',
        },
      },
      productSelected: 'excel',
      productDisplayState: 0,
      productDisplayTimeout: null,
    }
  },
  mounted () {
    this.rotateProductDisplay();
  },
  methods: {
    stopRotate () {
      if (this.productDisplayTimeout) {
        clearTimeout(this.productDisplayTimeout)
      }
    },
    rotateProductDisplay () {
      const productList = ['excel', 'outlook', 'powerpoint'];

      const newState = this.productDisplayState + 1;

      if (newState > (productList.length - 1)) {
        this.productDisplayState = 0;
      } else {
        this.productDisplayState = newState;
      }

      this.productSelected = productList[this.productDisplayState];

      this.productDisplayTimeout = setTimeout(() => {
        this.rotateProductDisplay();
      }, 3500);
    },
  },
}
</script>
<style lang="scss">


</style>

