<template>
    <div id="product-card">

    </div>
</template>

<script>
export default {
    name: 'ProductCard',
    props: {
        productSelected: {
            type: String,
        },
        product: {
            type: Object,
        }
    },
    computed: {
        cardColour () {
            switch (this.productSelected) {
                case 'excel':
                    return 'card-colour-green';
                case 'powerpoint':
                    return 'card-colour-orange';
                case 'outlook':
                    return 'card-colour-blue';
                default:
                    return 'card-colour-default';
            }
        },
        primaryTagFirst () {
            switch (this.productSelected) {
                case 'excel':
                    return true;
                case 'powerpoint':
                    return true;
                case 'outlook':
                    return false;
                default:
                    return true;
            }
        },
    }
}
</script>

<style lang="scss">

</style>
