<template>
    <div class="">
        <div class="flex flex-col justify-center items-center lg:max-w-lg">
            <!-- <span class="mx-3 mb-3">Ask about our implementation services.</span> -->
            <div v-if="alreadyInquired" class="text-center">Thanks for inquiring. A member of our Implementation Team will reach out shortly. </div>
            <div v-else class="w-full">
                <input class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="email" type="email" placeholder="Work email" v-model="email">
                <textarea class="resize-y appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" :placeholder="inquiryPlaceholder || 'So, how can we help?'" rows="4" v-model="inquisition"></textarea>
                <div :class="inquisition.length ? 'visible' : 'invisible'" class="flex flew-row justify-end w-full text-xs text-stone-500">
                    <span>{{ inquisition.length }}/{{ minInquisitionLength }}</span>
                </div>
                <button class="bg-opacity-85 rounded w-full py-2 px-3 mt-2 text-white font-black italic leading-tight tracking-wide bg-orange-500 hover:cursor-pointer hover:bg-opacity-25 border border-orange-500 hover:text-orange-500 border-opacity-85" :class="true || (email.length && inquisition.length > minInquisitionLength) ? 'visible' : 'invisible'" @click.stop="inquire">Learn More</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Inquiry',
    props: {
        inquiryPlaceholder: {
            type: String,
            required: false,
        }
    },
    data () {
        return {
            inquiring: false,
            email: '',
            inquisition: '',
            alreadyInquired: false,
            minInquisitionLength: 40,
        }
    },
    methods: {
        async inquire () {
            try {
                this.inquiring = true;

                var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            
                if (!this.email || !regex.test(this.email)) {
                    alert('Please enter a valid email address.');
                    return;
                }
                
                if (!this.inquisition || this.inquisition.length < this.minInquisitionLength) {
                    alert('Please provide a bit more detail.');
                    return;
                }

                const url = process.env.VUE_APP_API_URL;
                const response = await axios.post(`${url}/inquire`, {
                    email: this.email,
                    inquisition: this.inquisition,
                });
                
                this.alreadyInquired = true;
            } catch (e) {
                alert('It looks like we goofed. Terribly sorry. You\'re welcome to email us at info@skoookum.com');
            } finally {
                this.inquiring = false;
            }
        },
    },
}
</script>
