<template>
    <div class="flex flex-row justify-between items-center px-6 pt-4">
      <router-link id="logo-text" class="hidden sm:block" to="/">
        <div class="font-semibold text-stone-900 text-3xl">skoookum</div>
      </router-link>
      <router-link to="/">
        <img id="logo" src="sk3kum_912.png" alt="Skoookum Logo" class="w-10 h-10">
      </router-link>
      <div class="">
        <!-- <router-link class="nav-item" to="/about">About</router-link> -->
        <router-link class="header-route spacer-right-l" to="/products" activeClass="underline">Products</router-link>
        <router-link class="header-route" to="/services" activeClass="underline">Services</router-link>
      </div>
    </div>
</template>