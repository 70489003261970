<template>
  <div class="flex flex-col h-screen bg-[#f1f2f3]">
    <div class="content-boundaries ">
      <TopBar />
    </div>
    
    <div class="flex flex-grow">
      <router-view/>
    </div>
    
    <!-- Footer -->
    <div class="content-boundaries sm:px-6">
      <CustomFooter />
    </div>
    
  </div>
</template>
<script>
import TopBar from './components/TopBar';
import CustomFooter from './components/Footer';

export default {
  name: 'App',
  components: {
    TopBar,
    CustomFooter,
  }
}
</script>
