<template>
    <div class="lg:pt-32 w-full mb-16 lg:mb-48">
        <div class=" pt-16 pb-16 lg:pb-32 px-6 flex flex-col justify-center items-center lg:flex-row content-boundaries">
            <div class="flex flex-col w-auto  items-center text-center">
                <h1 class="text-4xl md:text-5xl lg:text-6xl xl:text-7xl italic font-black text-stone-900 ">Spreadsheets</h1>
                <h1 class="font-black text-4xl md:text-5xl lg:text-6xl xl:text-7xl italic text-stone-900 leading-none ">that Scale</h1>
                <FindUs />
            </div>
        </div>
        <!-- <div class="bg-[#217346] text-stone-100 flex flex-col justify-center items-center font-black text-2xl px-6"> -->
            <!-- <div class="'bg-[#f1f2f3] px-2 pb-4  rounded-b'">
                <img class="w-full" src="@/assets/ui_v2_bottom.png" alt="Skoookum UI">
            </div> -->
            
        <!-- </div> -->
        <!-- <div class="bg-gradient-to-t from-[#217346] via-[#217346] to-[#f1f2f3] pt-8 px-6"> -->
        <div class="relative">
            <div class="bg-[#217346] px-6  absolute  left-0 right-0 h-3/5 inset-1/4  w-full z-20 -skew-y-6" ></div>
            <div class="px-6 flex flex-row justify-center  sm:content-boundaries">
            <!-- <div class="sm:bg-[#217346] sm:w-64 sm:p-4 sm:rounded mx-auto"> -->
                <div class="bg-stone-300 px-2 pb-2 pt-3 rounded z-30 relative mx-6 overflow-hidden h-128 sm:h-auto sm:w-2/3 max-w-lg">
                    <img class="object-cover object-top w-full rounded-t" src="@/assets/ui_v2.png" alt="Skoookum UI">
                </div>
            </div>
        </div>

        <!-- <div class="content-boundaries p-48">
            <div class="flex flex-row justify-center">

                <span class="text-3"></span>
            </div>
        </div> -->
        <!-- <div class="px-6 mb-16 pb-8 bg-gradient-to-b from-[#f1f2f3] via-[#f1f2f3] to-[#217346]">
            <div class="bg-[#f1f2f3] px-2 pb-4 rounded-b">
                <img class="w-full" src="@/assets/ui_v2_bottom.png" alt="Skoookum UI">
            </div>        
        </div>         -->
    </div>
</template>

<script>
import FindUs from '../components/FindUs';
export default {
    name: 'Products',
    components: {
        FindUs,
    }
}
</script>
